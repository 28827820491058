import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from './demo/error/error.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthGuard } from './guards/auth.guard'
import { DepositslipComponent } from './depositslip/depositslip.component';
import { PaymentgatewaystatusComponent } from './demo/wallettopup/wallettopuprequest/paymentgateway/paymentgatewaystatus/paymentgatewaystatus.component';
// import { OperatorComponent } from './demo/operator/operator.component';

const routes: Routes = [
  {
    path: 'v1',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard/analytics',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'fundtransfer',
        canActivate: [AuthGuard],
        // loadChildren: () => import('./demo/reports/reports.module').then(module => module.ReportModule)
        loadChildren: () => import('./demo/fundtransfer/fundtransfer.module').then(module => module.FundtransferModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        // loadChildren: () => import('./demo/reports/reports.module').then(module => module.ReportModule)
        loadChildren: () => import('./demo/reports2/reports.module').then(module => module.ReportsModule)
      },
      {
        path: 'cashout',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/cashout/cashout.module').then(module => module.CashoutModule)
      },
      {
        path: 'payment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/payment/payment.module').then(module => module.PaymentModule)
      },
      {
        path: 'wallettopup',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/wallettopup/wallettopup.module').then(module => module.WallettopupModule)
      },
      {
        path: 'commission',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/commission/commission.module').then(module => module.CommissionModule)
      },
      {
        path: 'aeps',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/aeps/aeps.module').then(module => module.AepsModule)
      },
      {
        path: 'payout',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/payout-transaction/payout-transaction.module').then(module => module.PayoutTransactionModule)
      },
      {
        path: 'accountvalidation',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/account-validation/account-validation.module').then(module => module.AccountValidationModule)
      },
      {
        path: 'insurance',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/insurance/insurance.module').then(module => module.InsuranceModule)
      },
      {
        path: 'bbps',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/bbps/bbps.module').then(module => module.BbpsModule)
      },
      {
        path: 'matm',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/matm/matm.module').then(module => module.MatmModule)
      },
      {
        path: 'pos',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/reports2/pos/pos.module').then(module => module.PosModule)
      },
      {
        path: 'invoicing',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/invoice/invoice.module').then(module => module.InvoiceModule)
      },
      {
        path: 'setting-page',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/settings-page/settings-page.module').then(module => module.SettingsModule)
      },
      {
        path: 'old',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/oldreports/oldreports.module').then(module => module.OldreportsModule)
      },
      {
        path: 'oldcommission',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/old-commission/old-commission.module').then(module => module.OldCommissionModule)
      },
      // {
      //   path: 'walletInterchange',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./demo/interchange/interchange.module').then(module => module.InterchangeModule)
      // },
      {
        path: 'walletcashout',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/wallet-cashout/wallet-cashout.module').then(module => module.WalletCashoutModule)
      },
    ]
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: 'depositslip',
    component: DepositslipComponent
  },
  {
    path: 'paymentGatewayStatus',
    component: PaymentgatewaystatusComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
