import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ErrorComponent } from './demo/error/error.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { StorageModule } from '@ngx-pwa/local-storage';
import {MatDialogModule} from '@angular/material/dialog';
// FCM Packages
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PushNotifyService } from './push-notify.service';
// Socket Package
import { Socket2Service } from './socket2.service';
import { DepositslipComponent } from './depositslip/depositslip.component';
import { StorageService } from './storage.service';
import { PaymentgatewaystatusComponent } from './demo/wallettopup/wallettopuprequest/paymentgateway/paymentgatewaystatus/paymentgatewaystatus.component';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

const fireBaseConfig = {
  apiKey: "AIzaSyATxpFql9-yuh2FKtTYJSDcGp4b1unNdXE",
  authDomain: "iserveumainapp.firebaseapp.com",
  databaseURL: "https://iserveumainapp.firebaseio.com",
  projectId: "iserveumainapp",
  storageBucket: "iserveu_storage",
  messagingSenderId: "421535327263",
  appId: "1:421535327263:android:ad016c8ce5814f655ae12a"
};

// staging starts
// const fireBaseConfig = {
//   apiKey: "AIzaSyCsGYdIxiZC3f9iNf00FaMCx-0juPWZzt0",
//   authDomain: "iserveustaging.firebaseapp.com",
//   databaseURL: "https://iserveustaging.firebaseio.com",
//   projectId: "iserveustaging",
//   storageBucket: "iserveustaging",
//   messagingSenderId: "240998976332",
//   appId: "1:240998976332:android:99f64b1f44d709bd189de9"
// };

// staging starts
// const fireBaseConfig =
// {
//   "type": "service_account",
//   "projectId": "iserveustaging",
//   "storageBucket": "iserveustaging",
//   "apiKey": "5ac8e058f2166775a80038550c4775e78d01e90d",
//   "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCTyX4yFa+saXU5\nwDxTcCUQGyqJmA+5pC58KkTOkCQ5VbgGzfqZXMAZLG3w/NzJ5VH5GJy9Dk9g18NI\n27tDv6d5oBav92YVSO3N0HjjtF8krPEkXvhyrMPqZ+i9644t3Vk5VmbwJmhVKdoJ\nhXK/bP8Au1l834JgheOi7LxxTUApqNTl1inoVV7fXNGwIo8+k+67aGthr4ApapLY\n0y27nGodl4qAzpNXSXcKbVszpEijw/5YboVHInjhFzsZtu3m0G7fhdjKNYApXIXY\nnyuQRsywsiVxrt4B992L46gsedPluC2mA/p0dRGBtJ2L3UBkwkkuzJN80y48vypB\nDoh7GRqvAgMBAAECggEABuPL6FXZJ3aQf9gVzTtbNFGv5/5Dgr9uvgcnx2GHmXda\nPvxMrz2tualimWuQd4Z4uIjh4HEnA412OJKrO6YBuXUx2ZOfxLpDq+azhsTFSBZv\n9ObLOmgSMilp+6tcU4hlkntpYnodPxXWaRSO8ZxelGV87zS/BUpN/y3TkzdWGHBe\nYyxAqHEN60Mhcdut8RwyE8tyT4u874vTlgC5K1f+I57P2CmYV55TXA2lopWBC/et\nKV+gnynTRml+lE3oMp4lWyT5HvGmSYh42AanNGwln7MRZhvnhIoKzkfpec6UHkRf\nqFAuoUcnIk1gMqLdTXbMR1WDiPrYbR08g5uKRyP3QQKBgQDNEzLxpLgkvOrbI8dP\ndh79N9nJI1kt7r6erx9VjH7fBg06aqEwarZrLLlkZuYGMsaJ3QJIaDcE33ui7vAQ\nRFseMPVszI55SRkmPuVVWWrGF3uQdX6/sPPU366hSqdWcex64cYB8G9vZorS9YPV\nMFsHV1eGX0d7hhEHJL47DCuinwKBgQC4fHMyD77hLl3oDcxhLRo9wv8fyU8v0wvE\npDr63dsA0YaPLlrsvMJja6rGBA86xvOkGO8PHLe/SKa9KZAjW6gXw/x8a5LQiclK\ncbequNdGH2Ir30/EAwSluFL8y5YAXrIfEyC7yWqlnXBMg2+ZijGZP9GJP2uEd08Y\n0VjNdccd8QKBgQCbqaEXzyJ/sG7M3IGjjVPEfapwhMZimUBUDzXHqBd9john+RxV\nNgs1mwM2dOzRc6VEbGAZOhHZPxIIATpz/o7zgdIpPbQ/1/6hC0jk2jS0HIsMgkMt\nmlHbGdbiU1plwZ/xPoMgYbMUak+iBf6FymiuT9v+AUCvQHqYY4mKJJjSnQKBgETX\nM4kftQOD8MixtW0hjmQzik3LKcBXyVFkUxnfhWRFyydMjeM/AG5m87+WLjSpnqrC\nHxqNhcdcDdQSWVXIUu4jVOj8nzkv4SvlSTrsZHAnL9PdLX+f/LD2Ggl40o1qyJ6t\n3Y4VN7V7DTeP6CnsFlvwI3lKcA1rE9nX7IImi9mRAoGAJrNQ61l9ReTKQvFOcBdi\niJjHHlw3LcBtvuLyeW/ys7Zc9dkpqHUq2Ns4rJL1h96BE4cvA5M98BJ9t7qhq99c\nuVnhE2eZb5p7ROXU+MWfz0E6nu9WRdJ97E1mxgeqG+mXfReUqYBPI1yAP6x5VcC6\nkmpbFfZR7qJ4KfrgkBEJnLU=\n-----END PRIVATE KEY-----\n",
//   "client_email": "firebase-adminsdk-31xxv@iserveustaging.iam.gserviceaccount.com",
//   "client_id": "116490017586369722655",
//   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
//   "token_uri": "https://oauth2.googleapis.com/token",
//   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
//   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-31xxv%40iserveustaging.iam.gserviceaccount.com"
// }

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    ErrorComponent,
    DepositslipComponent,
    PaymentgatewaystatusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    // StoreModule.forRoot({ dashboard: dashboardReducer }),
    StorageModule.forRoot({ IDBNoWrap: true }),
    AngularFireModule.initializeApp(fireBaseConfig),
    AngularFireMessagingModule, AngularFirestoreModule,
    MatDialogModule,
    // AngularMultiSelectModule
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    NavigationItem, DatePipe, PushNotifyService, Socket2Service, StorageService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
