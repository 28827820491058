import { Injectable, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import * as vex from 'vex-js';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  tokenAuth?: boolean;
  hideInBreadcrumb?: boolean;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}


@Injectable()
export class NavigationItem {
  constructor(
    private appService: AppService,
  ) { 
    this.fetchUserName(); 
  }
  fetchedUserName: any;
  fetchUserName = () =>{
    this.appService.username$.subscribe((res: any)=>{
      console.log(res);
      this.fetchedUserName = res;
    }, (err: any) =>{
      console.log(err);
    }
    )
  }

  services = {
    retailer: [
      // {
      //   id: 'moneytransfer',
      //   title: 'Money Transfer',
      //   type: 'item',
      //   url: '/v1/fundtransfer',
      //   icon: 'feather icon-home'
      // },
      {
        id: 'transReport', 
        title: 'Transaction Report',
        type: 'collapse',
        icon: 'feather icon-award',
        children: [
          {
            id: 'dmt2',
            title: 'DMT',
            type: 'item',
            url: '/v1/reports/dmt2',
            _featureId: 'N/A'
          },
          {
            id: 'recharge2',
            title: 'Recharge',
            type: 'item',
            url: '/v1/reports/recharge2',
            _featureId: 'N/A'
          },
          {
            id: 'upi',
            title: 'UPI',
            type: 'item',
            url: '/v1/reports/upi',
            _featureId: 'N/A'
          },
          {
            id: 'commission',
            title: 'Commission',
            type: 'item',
            url: '/v1/reports/commission',
            _featureId: 'N/A'
          },
          {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: '/v1/reports/wallet',
            _featureId: 'N/A'
          },
          {
            id: 'cashout',
            title: 'Cashout',
            type: 'item',
            url: '/v1/reports/cashout'
          },
          {
            id: 'aeps',
            title: 'AEPS',
            type: 'item',
            url: '/v1/aeps',
            _featureId: 'N/A'
          },
          {
            id: 'bbps',
            title: 'BBPS',
            type: 'item',
            url: '/v1/bbps',
            _featureId: 'N/A'
          },
          {
            id: 'insurance',
            title: 'Insurance',
            type: 'item',
            url: '/v1/insurance',
            _featureId: 'N/A'
          },
          {
            id: 'matm',
            title: 'MATM',
            type: 'item',
            url: '/v1/matm',
            _featureId: 'N/A'
          },
          {
            id: 'pos',
            title: 'POS',
            type: 'item',
            url: '/v1/pos',
            _featureId: 'N/A'
          },
          {
            id: 'payout',
            title: 'Payout',
            type: 'item',
            url: '/v1/payout',
            _featureId: 'N/A'
          },
          {
            id: 'account_validation',
            title: 'Account_validation',
            type: 'item',
            url: '/v1/accountvalidation',
            _featureId: 'N/A'
          },
        ]
      },
      {
        id: 'invoicing',
        title: 'Invoice & Compliance',
        icon: 'feather icon-book',
        type: 'item',
        url: '/v1/invoicing',
        _featureId: 'N/A'
      },
      {
        id: 'wallettopup',
        title: 'Wallet-Top-Up',
        icon: 'feather icon-command',
        type: 'collapse',
        children: [
          {
            id: 'wallettopuprequest',
            title: 'Request',
            type: 'item',
            // icon: 'feather icon-home',
            url: '/v1/wallettopup/wallettopuprequest',
            _featureId: 'N/A'
          },
          {
            id: 'wallettopuprequestreport',
            title: 'Report',
            type: 'item',
            // icon: 'feather icon-home',
            url: '/v1/wallettopup/wallettopuprequestreport',
            _featureId: 'N/A'
          }
        ]
      },

      {
        id: 'walletcashout',
        title: 'Wallet Cashout',
        icon: 'feather icon-layers',
        type: 'item',
        url: '/v1/walletcashout',
        _featureId: 'N/AA'
      }
    ],
    nonRetailer: [
      {
        id: 'transReport',
        title: 'Transaction Report',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'dmt2',
            title: 'DMT',
            type: 'item',
            url: '/v1/reports/dmt2'
          },
          {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: '/v1/reports/wallet'
          },
        ]
      }
    ]
  }

  navItems = [

    {
      id: 'ui-element',
      // title: 'iServeU Pvt. Ltd.',
      type: 'group',
      icon: 'feather icon-layers',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/v1/dashboard/analytics',
          icon: 'feather icon-home',
          external: false
        }
      ]
    },
    {
      id: 'services',
      title: 'SERVICES',
      type: 'group',
      icon: 'feather icon-layers',
      children: []
    },
    // {
    //   id: 'commission',
    //   title: 'Commission',
    //   type: 'item',
    //   icon: 'feather icon-layers',
    //   url: 'https://partner-iserveu.web.app/authentication/auth-token/',
    //   target: true,
    //   external: true,
    //   tokenAuth: true
    // }
  ];

  public get() {
    let userRole = '';
    try {
      const { userInfo: { userType } } = JSON.parse(sessionStorage.getItem('dashboardData'));
      userRole = userType;
    } catch (error) {
      window.location.reload();
    }

    // console.log(`%c ${this.userRole}`, 'color: green; font-weight: bold; font-size: 10rem;')

    this.navItems.find(item => item.id === 'services').children = (userRole === 'ROLE_RETAILER') ? <any>this.services.retailer : <any>this.services.nonRetailer;
    // this.navItems.find(item => item.id === 'commission').title = (userRole === 'ROLE_ADMIN') ? 'Plan Configuration' : 'My Plan';

    // return NavigationItems;
    return this.navItems;
  }
}
