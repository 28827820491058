import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";

import { StorageMap } from "@ngx-pwa/local-storage";
import jwt_decode from 'jwt-decode';
import * as vex from 'vex-js';
import { finalize } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { Socket2Service } from "./socket2.service";
import { AuthConfig } from "./app-config";
import { AuthApi } from "./auth/auth.api";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  walletBalance = new BehaviorSubject(0);
  wallet2Balance = new BehaviorSubject(0);
  username$ = new BehaviorSubject('default');
  logOutTimer: any = undefined;
  constructor(
    private router: Router,
    private storage: StorageMap,
    private http: HttpClient,
    private ngxSpinner: NgxSpinnerService,
    private socket2Service: Socket2Service,
  ) { }

  autoLogOut() {
    vex.closeAll(); // Close all vex dialogs
    const tokenData: { exp: number } = jwt_decode(sessionStorage.getItem('CORE_SESSION'));
    const startDate = new Date();
    const expDate = new Date(tokenData.exp * 1000);
    const session = Math.ceil((<any>expDate - <any>startDate));

    // setInterval(() => {
    //     //console.log('Start Date: ', startDate);
    //     //console.log('Exp Date: ', expDate);
    //     //console.log('Check Timing: ', new Date());
    // }, 10000);

    // Clear the Previous timeout before instatntiating another. Useful for preventing multiple instances of Timeout.
    if (this.logOutTimer) {
      clearTimeout(this.logOutTimer);
    }

    this.logOutTimer = setTimeout(() => {
      window.removeEventListener('offline', this.handleOffline, true);

      // console.clear(); // Clear the Console.
      sessionStorage.clear();
      this.storage.clear().subscribe(() => { });
      this.router.navigate(['/']);
    }, session);
  }

  logOut() {
    window.removeEventListener('offline', this.handleOffline, true);
    sessionStorage.clear();
    localStorage.removeItem('userRole');
    localStorage.removeItem('adminName');
    localStorage.removeItem('userName');
    localStorage.removeItem('checked');
    localStorage.removeItem('rotaryu');
    console.clear(); // Clear the Console.
    vex.closeAll(); // Close all vex dialogs
    sessionStorage.clear();
    this.storage.clear().subscribe(() => { });
    this.router.navigate(['/']);
  }
  setting() {
    this.router.navigate(['/v1/setting-page']);
  }

  async fetchWalletBalance() {
    const tokenData: {sub: string} = jwt_decode(sessionStorage.getItem('CORE_SESSION'));
    var encodeUrl = await AuthConfig.config.encodeUrl('https://grpcwalletprod-new.iserveu.tech/wallet/checkbalance');
    this.http.post(encodeUrl, {user_name: tokenData.sub}) // Production
    .pipe(finalize(() => { this.ngxSpinner.hide('walletSpinner'); }))
    .subscribe(
      (res: any) => {
        // //console.log('Wallet Balance Response: ', res);
        this.walletBalance.next(res.response.Balance); // Update Wallet Balance.
      },
      (err: any) => {
        // //console.log('Wallet Balance Error: ', err);
        // vex.dialog.alert(err.error.errorMessage);
        this.walletBalance.next(0);
      }
    );
  }

  async fetchWallet2Blanace() {
    let encUrl = await AuthConfig.config.encodeUrl(AuthApi.url.wallet2)

    this.http.get(encUrl)
    .pipe(finalize(() => { this.ngxSpinner.hide('wallet2Spinner'); }))
    .subscribe(
      (res: any) => {
        //console.log('Wallet 2 Balance Response: ', res);
        this.wallet2Balance.next(res);
      },
      (err: any) => {
        //console.log('Wallet 2 Balance Error: ', err);
        this.wallet2Balance.next(0);
      }
    )
  }

  fetchNotifications(notiReqBody: any) {
    // return this.http.post('https://unifiedfcmhub-vn3k2k7q7q-uc.a.run.app/fetch_notification', notiReqBody);
    return this.http.post('https://unifiedfcmhub.iserveu.tech/fetch_notification', notiReqBody);
  }

  observeInternetConn() {
    //console.log('Checking Internet Offline Status');
    
    window.addEventListener('offline', this.handleOffline, true);
  }

  handleOffline = (evt) => {
    //console.log('Internet Disconnected.')
    // //console.log('Internet Disconnected Event: ', evt);

    // Disconnect Socket, if internet disconnected
    if (this.socket2Service.isConnected) {
      this.socket2Service.disconnectSocket();
    }

    // vex.dialog.alert('Internet Disconnected');
  }
}
