import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireMessaging } from '@angular/fire/messaging'
import { HttpClient } from "@angular/common/http";
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class PushNotifyService {
    notification = new BehaviorSubject(null);
    notifData: any;

    constructor(private ngFireMessaging: AngularFireMessaging, private http: HttpClient) { }


    requestPermission() {
        // //console.log('Requesting Permission for Notification');
        const user: {sub: string} = jwt_decode(sessionStorage.getItem('CORE_SESSION'));
        this.ngFireMessaging.requestToken.subscribe(
            (token) => {
                //console.log('Notification Permission: ', token);
                
                const reqBody = {
                    token : token,
                    topicName : `DMT_STAGING_${user.sub}`
                };
                this.notifData = reqBody;

                //console.log('Push Notification Listening On: ', reqBody.topicName);

                this.consumeNotificationAPI(reqBody).subscribe(
                    (res: any) => {
                        // //console.log('Push Notification Subscribed.');
                        // //console.log('Push Notification Subscription Response: ', res);
                        this.receiveMessage();
                    },
                    (err: any) => {
                        //console.log('Notification Subscribe Error: ', err);
                    }
                );
            },
            (err: any) => {
                //console.log('Notification Permisssion Denied');
                //console.log('Error: ', err);
            }
        );
    }
     
    receiveMessage() {
        this.ngFireMessaging.messages.subscribe(msg => {
            // //console.log("Notification Message Pushed: ", msg);
            // //console.log('Notify Subscribed Components');
            this.notification.next(msg);
        })
    }

    consumeNotificationAPI(reqBody) {
        return this.http.post('https://subscribeandunsubscribetoken-vn3k2k7q7q-uc.a.run.app/subscribetotopic', reqBody);
    }

    unsubNotification() {
        this.http.post('https://subscribeandunsubscribetoken-vn3k2k7q7q-uc.a.run.app/unsubscribetotopic', this.notifData)
        .subscribe(
            (res: any) => {
                //console.log('Unsubscribe Notification Res: ', res);
            },
            (err: any) => {
                //console.log('Unsubscribe Notification Error: ', err);
            }
        );
    }

}